<template>
  <div class="custom-card w-100 card d-flex flex-column">
    <div
      class="
        custom-card-header
        d-flex
        justify-content-between
        align-items-center
        px-2
      "
    >
      <div class="d-flex align-items-center">
        <div style="color: #4361ee; font-size: 22px">
          <i class="fas fa-building"></i>
        </div>
        <div class="mx-2">
          <h5 class="mb-0">Tenant Building Details</h5>
          <p class="mb-0" style="opacity: 0.7">
            Details about the building, bed, date of joining..
          </p>
        </div>
      </div>
      <div v-if="currentPage > 1" style="cursor: pointer" @click="isOpen = !isOpen">
        <i class="fas fa-angle-down"></i>
      </div>
      <div v-if="currentPage <= 1" style="cursor: pointer">
        <i class="fas fa-angle-down"></i>
      </div>
    </div>
    <div class="custom-card-details" v-if="isOpen">
      <div class="container mb-4">
        <div class="row w-full">
          <div class="col-4">
            <label class="mb-0"><h6>Building Name</h6></label>
            <!-- <input type="text" class= "form-control" placeholder="Enter Building Name" v-mmodel='building_name'> -->
            <multiselect
              v-model="pageTwo.selectedBuilding"
              :options="pageTwo.buildingsData"
              track-by="id"
              label="name"
              placeholder="Select one"
              class="helo"
            ></multiselect>
          </div>
          <div class="col-4">
            <label class="mb-0"><h6>Bed ID</h6></label>
            <input
              type="text"
              class="form-control"
              placeholder="Enter Bed ID"
              v-model="pageTwo.bed_id"
            />
          </div>
          <div class="col-4 ">
            <div class="form-group mb-3">
              <label>Created At</label>
              <br />
              <date-picker
                v-model="pageTwo.createdAt"
                :first-day-of-week="1"
                lang="en"
              ></date-picker>
            </div>
          </div>
        </div>
      </div>
      <div class="container mb-4">
        <div class="row w-full">
          <div class="col-4">
            <div class="form-group mb-3">
              <label>Unit ID</label>
              <!-- <input type="text" placeholder="Enter Unit ID" class="form-control" v-model='unit_id'> -->
              <multiselect
              v-model="pageTwo.unit_id"
              :options="pageTwo.unit_data"
              track-by="id"
              label="name"
              class="helo"
            ></multiselect>
            </div>
          </div>
         <div class="col-4 ">
            <div class="form-group mb-3">
              <label>Date of Joining</label>
              <br />
              <date-picker
                v-model="pageTwo.dateOfJoining"
                :first-day-of-week="1"
                lang="en"
              ></date-picker>
            </div>
          </div>
        </div>
      </div>
    <button class="btn btn-primary" v-if="loadingBtn == 0" @click="useTenantBuildingInfo">Next</button>
    <button class="btn btn-secondary" v-if="loadingBtn != 0">Submitting</button>
    </div>
  </div>
</template>
<script>
// import Multiselect from "vue-multiselect";
// import DatePicker from "vue2-datepicker";
import Vue from 'vue';
import axios from 'axios';
import { tenantPage2 } from '@/utils/apis.js';
import { getUnits } from '@/utils/apis.js';
import { getBuildingInfo } from '@/utils/apis.js';
import Multiselect from "vue-multiselect";
import DatePicker from "vue2-datepicker";
import {getUnitsByBuilding} from '@/utils/apis.js';
Vue.use (axios)

export default {
  data() {
    return {
      // options: [
      //   "India",
      //   "United States of America",
      //   "Great Britain",
      //   "Scotland",
      //   "Ireland",
      //   "Kenya",
      //   "Zimbabwe",
      //   "Sri Lanka",
      //   "Bangladesh",
      //   "Pakistan",
      // ],
      isOpen: false,
      id: "",
      bed_id: "",
      unit_id:[],
      building_name: [],
      building:[],
      unit:[],
      // selectedUnit: "",
      // createdAt: "",
      // dateOfJoining: "",
    };
  },
  methods: {
    async getUnitName() {
      const response= await getUnits();
      console.log(response.results);
      let newData=[];
      response.results.map((item) => newData.push(item.id))
      this.building=[...newData]
    },
    async getBuildingName() {
      const response= await getBuildingInfo();
      console.log(response.results);
      let newData=[];
      response.results.map((item) => newData.push(item.name))
      this.building_name=[...newData]
    },
    async sendBuildingInfo(e){
      e.preventDefault()
      const body = {       
      id: 1,
      bed_id: this.bed_id,
      unit_id: this.unit_id,
      building_name: this.building_name,
       }
      let result = await tenantPage2(body);
      console.log(result);
      if (!this.building_name || !this.bedId || !this.unit_id) {
        alert("Something you filled wrong");
      }
    },
    async useGetUnitsByBuilding(id){
      try {
       const response = await getUnitsByBuilding(id);
       console.log(response.results); 
      } catch (error) {
        console.log(error);
      }
    }

  },
  components: {
    Multiselect,
    DatePicker,
  },
  props: [ "pageTwo", "useTenantBuildingInfo", "loadingBtn", "currentPage" ],
  watch: {
    pageTwo: {
      handler(newVal, oldVal) {
        console.log("changed", oldVal.selectedBuilding.id , newVal.selectedBuilding.id);
        if(oldVal.selectedBuilding.id != newVal.selectedBuilding.id){
          this.useGetUnitsByBuilding(newVal.selectedBuilding.id)
        }
      },
      deep: true,
    },
    currentPage: function(val){
      if(val > 1){
        this.isOpen = true;
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.custom-card {
  min-height: 6rem;
  padding: 1rem;
}
.custom-card-header {
  height: 6rem;
}
</style>
