<template>
  <div class="custom-card w-100 card d-flex flex-column">
    <div
      class="custom-card-header d-flex justify-content-between align-items-center px-2"
    >
      <div class="d-flex align-items-center">
        <div style="color:#4361EE;font-size:22px;">
          <i class="fas fa-money-check"></i>
        </div>
        <div class="mx-2">
          <h5 class="mb-0">Tenant Rental Details</h5>
          <p class="mb-0" style="opacity:0.7;">Enter information about rental plan, due dates..</p>
        </div>
      </div>
      <div v-if="currentPage > 2" style="cursor:pointer" @click="isOpen = !isOpen"><i class="fas fa-angle-down"></i></div>
      <div v-if="currentPage <= 2" style="cursor:pointer"><i class="fas fa-angle-down"></i></div>

    </div>
    
    <div class="custom-card-details" v-if="isOpen">
      <!-- <div class="container mb-4 d-flex align-items-center">
        <input type="checkbox" name="" id="" @click="pageThree.is_individual_company_account = !pageThree.is_individual_company_account" />
        <h6 class="mb-0 mx-1">Are you a Individiual/Company(Account)(B2B)?</h6>
      </div> -->
      <div class="container mb-4">
        <div class="row w-full">
          <div class="col-4 ">
            <label class="mb-0"><h6>Tenant Type</h6></label>
            <multiselect
              v-model="pageThree.tenant_type"
              :options="individualOrCompanyOptions"
              class="helo"
            ></multiselect>
          </div>
          <!-- <div class="col-4 ">
            <label class="mb-0"><h6>ID</h6></label>
            <input
              type="text"
              class="form-control"
              placeholder="Enter ID"
              v-model="id"
            />
          </div> -->
          <div class="col-4 ">
            <label class="mb-0"><h6>Account Name</h6></label>
            <input
              type="text"
              class="form-control"
              placeholder="Enter Account Name"
              v-model="pageThree.account_name"
            />
          </div>
        </div>
      </div>
      <div class="container mb-4">
        <div class="row w-full">
          <div class="col-4 ">
            <label class="mb-0"><h6>Advance Amount</h6></label>
            <input
              type="number"
              class="form-control"
              placeholder="₹ Enter Amount "
              v-model="pageThree.advance_amount"
            />
          </div>
          <div class="col-4 ">
            <div class="form-group mb-3">
              <label>Due Date</label>
              <br />
              <date-picker
                v-model="pageThree.due_date"
                :first-day-of-week="1"
                lang="en"
              ></date-picker>
            </div>
          </div>
          <div class="col-4 ">
            <label class="mb-0"><h6>Monthly rental plan</h6></label>
            <input
              type="number"
              class="form-control"
              placeholder="₹ Enter Rental Plan "
              v-model="pageThree.monthly_rental_plan"
            />
          </div>
        </div>
      </div>
      <div class="container mb-4">
        <div class="row w-full">
          <div class="col-4 ">
            <label class="mb-0"><h6>Old Due</h6></label>
            <input
              type="number"
              class="form-control"
              placeholder="₹ Enter Old Due Amount "
              v-model="pageThree.old_due"
            />
          </div>
          <div class="col-4 ">
            <label class="mb-0"><h6>Total Due</h6></label>
            <input
              type="number"
              class="form-control"
              placeholder="₹ Enter Total Due Amount "
              v-model="pageThree.total_due"
            />
          </div>
        </div>
      </div>
      <div class="container mb-4">
        <div class="row w-full">
          <div class="col-4 ">
            <label class="mb-0"><h6>Amount Collected</h6></label>
            <input
              type="number"
              class="form-control"
              placeholder="₹ Enter Amount Collected "
              v-model="pageThree.amount_collected"
            />
          </div>
          <div class="col-4 ">
            <label class="mb-0"><h6>Due / Pending</h6></label>
            <input
              type="number"
              class="form-control"
              placeholder="₹ Enter Total Due Amount "
              v-model="pageThree.due_pending"
            />
          </div>
        </div>
      </div>
    <button class="btn btn-primary" v-if="loadingBtn == 0" @click="useTenantRental">Submit</button>
    <button class="btn btn-secondary" v-if="loadingBtn != 0">Submitting</button>
    </div>
  </div>
</template>
<script>
import DatePicker from "vue2-datepicker";
import Multiselect from "vue-multiselect";
import Vue from 'vue';
import axios from 'axios';
import { tenantPage3 } from '@/utils/apis.js';
Vue.use (axios)

export default {
  data() {
    return {
      isOpen: false,
      id:"",
      advanced_amount: "",
      due_date: "",
      monthly_rental_plan: "",
      old_due: "",
      total_due: "",
      amount_collected: "",
      due_pending: "",
      compOrInd: false,
      individualOrCompanyOptions: ["Individual", "Company"],
      tenant_type: "",
      account_name: "",
      tenantBaiscStatus: false,
      tenantRentalStatus: false,
      tenantBuildingStatus: false,
    };
  },
  methods:{
    async sendRental(e){
      e.preventDefault()
      const body = {       
      id: this.id,
      advanced_amount: this.advanced_amount,
      due_date: this.due_date,
      monthly_rental_plan: this.monthly_rental_plan,
      old_due: this.old_due,
      total_due: this.total_due,
      amount_collected: this.amount_collected,
      due_pending: this.due_pending,
      
       }
      let result = await tenantPage3(body);
      console.log(result);
      if ( !this.advanced_amount || !this.due_date || !this.monthly_rental_plan || !this.old_due || !this.total_due || !this.amount_collected || !this.due_pending) {
        alert("Something you filled wrong");
      }
    },
    
  },
  components: {
    DatePicker,
    Multiselect,
  },
  props: ["pageThree", "useTenantRental", "loadingBtn", "currentPage"],
  watch:{
      currentPage: function(val){
      if(val > 2){
        this.isOpen = true;
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.custom-card {
  min-height: 6rem;
  padding: 1rem;
}
.custom-card-header {
  height: 6rem;
}
</style>
