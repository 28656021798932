<template>
  <div class="custom-card w-100 card d-flex flex-column">
    <div
      class="
        custom-card-header
        d-flex
        justify-content-between
        align-items-center
        px-2
      "
    >
      <div class="d-flex align-items-center">
        <div style="color: #4361ee; font-size: 22px">
          <i class="far fa-user-circle"></i>
        </div>
        <div class="mx-2">
          <h5 class="mb-0">Tenant Basic Details</h5>
          <p class="mb-0" style="opacity: 0.7">Enter your Basic Information</p>
        </div>
      </div>
      <div style="cursor: pointer" @click="isOpen = !isOpen">
        <i class="fas fa-angle-down"></i>
      </div>
    </div>
    <div class="custom-card-details" v-if="isOpen">
      <div class="container mb-4">
        <div class="row w-full">
          <div class="col-4">
            <label class="mb-0"><h6>Name</h6></label>
            <input
              type="text"
              class="form-control"
              placeholder="Enter Name"
              v-model="pageOne.name"
            />
          </div>
          <div class="col-4">
            <label class="mb-0"><h6>Email Address</h6></label>
            <input
              type="email"
              class="form-control"
              placeholder="Enter Email"
              v-model="pageOne.email"
            />
          </div>
          <div class="col-4">
            <div class="form-group mb-4">
              <label>Phone number</label
              ><input type="tel" class="form-control" v-model="pageOne.phone" /><span class="text-muted"
                >e.g "(xx) xxxx-xxxx"</span
              >
            </div>
          </div>
        </div>
      </div>
      <div class="container mb-4">
        <div class="row w-full">
          <div class="col-8">
            <label class="mb-0"><h6>Address</h6></label>
            <textarea
              name="address"
              id=""
              rows="4"
              placeholder="Enter Address"
              class="w-100 form-control"
              v-model="pageOne.address"
            ></textarea>
          </div>
          <!-- <div class="col-4">
            <label class="mb-0"><h6>Country</h6></label>
            <multiselect
              v-model="selectedCountry"
              :options="options"
              class="helo"
            ></multiselect>
          </div> -->
        </div>
      </div>
      <div class="container mb-4">
        <div class="row w-full">
          <div class="col-4">
            <label class="mb-0"><h6>State</h6></label>
            <multiselect
              v-model="pageOne.state"
              :options="options"
              class="helo"
            ></multiselect>
          </div>
          <div class="col-4">
            <label class="mb-0"><h6>City</h6></label>
            <input
              type="text"
              class="form-control"
              placeholder="Enter City"
              v-model="pageOne.city"
            />
          </div>
          <div class="col-4">
            <label class="mb-0"><h6>Zip / Postal code</h6></label>
            <input
              type="text"
              class="form-control"
              placeholder="Enter Postal Code"
              v-model="pageOne.zip"
            />
          </div>
        </div>
      </div>
      <div class="container mb-4">
        <!-- <button
          type="button"
          class="btn btn-primary waves-effect waves-light"
          v-b-modal.modal-lg

        >
          Upload Tenant Image
        </button> -->
        <b-modal
          id="modal-lg"
          size="lg"
          title="Upload Tenant Image"
          title-class="font-18"
          ok-title="Upload"
        >
          <div class="w-100 d-flex flex-column align-items-center">
            <div
              class="position-relative"
              id="display_image"
              style="height: 16rem; width: 20rem; background-color: black"
            >
              <img
                src="https://via.placeholder.com/200x200"
                alt=""
                class="w-100"
                style="height: 100%; object-fit: cover"
              />
              {{onFilechanged}}
            </div>
            <div
              class="text-primary my-2 pointer custom-upload-image"
              style="cursor: pointer"
            >
              <!-- <strong>Choose File</strong> -->
              <input
              placeholder="Choose File"
              type="file"
              id="building_file_input"
              @change="onFilechanged"
            />
            </div>
            <!-- <input
              placeholder="Choose File"
              type="file"
              id="building_file_input"
              @change="onFilechanged"
            /> -->
            <input
              type="text"
              class="form-control"
              style="max-width: 100%; width: 20rem"
              placeholder="Add Caption"
            />
          </div>
        </b-modal>
        <br/>
        <br/>
        <button
          id="myBtn"
          class="btn btn-primary"
          @click="useTenantDetails"
          v-if="loadingBtn == 0"
        >
          Next
        </button>
        <button
          id="myBtn"
          class="btn btn-secondary"
          v-if="loadingBtn != 0"
        >
          Submitting
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import Multiselect from "vue-multiselect";
import Vue from "vue";
import axios from "axios";
Vue.use(axios);
export default {
  data() {
    return {
      isOpen: true,
      selectedFile: null,
      files: [],
      justFiles: [],
      imageLink: [],
      file: null,
      dragging: false,
      name: "",
      phone: "",
      address: "",
      selectedCountry: "",
      city: "",
      zip: "",
      selectedState: "Andhra Pradesh",
      options: [ "Andhra Pradesh",
                "Arunachal Pradesh",
                "Assam",
                "Bihar",
                "Chhattisgarh",
                "Goa",
                "Gujarat",
                "Haryana",
                "Himachal Pradesh",
                "Jammu and Kashmir",
                "Jharkhand",
                "Karnataka",
                "Kerala",
                "Madhya Pradesh",
                "Maharashtra",
                "Manipur",
                "Meghalaya",
                "Mizoram",
                "Nagaland",
                "Odisha",
                "Punjab",
                "Rajasthan",
                "Sikkim",
                "Tamil Nadu",
                "Telangana",
                "Tripura",
                "Uttarakhand",
                "Uttar Pradesh",
                "West Bengal",
                "Andaman and Nicobar Islands",
                "Chandigarh",
                "Dadra and Nagar Haveli",
                "Daman and Diu",
                "Delhi",
                "Lakshadweep",
                "Puducherry"]
    };
  },

  methods: {
    onFilechanged(event) {
      this.selectedFile = event.target.files[0];
    },
  },
  components: {
    Multiselect,
  },

  props: ["loadingBtn", "pageOne", "useTenantDetails", "currentPage"],
};
</script>
<style lang="scss" scoped>
.custom-card {
  min-height: 6rem;
  padding: 1rem;
}
.custom-card-header {
  height: 6rem;
}

#myBtn:disabled {
  cursor: not-allowed;
  display: none;
}
</style>
