<template>
  <div class="w-100">
    <Preloader v-if="false" />
    <div class="custom-blank-navbar d-flex justify-content-between align-items-center w-100 bg-white px-4 py-2">
      <img src="@/assets/Pg-logo2.png" @click="home" style="cursor:pointer; height:3rem; width:auto;"/>
      <button class="btn btn-close" @click="back">x Close</button>
    </div>
    <div class="w-75 position-relative mx-auto">
      <h1 v-if="$router.currentRoute.path.includes('edit-tenant')">Edit Tenant Details</h1>
      <h1 v-else>Add Tenant Details</h1>
    </div>
    <div class="w-75 position-relative mx-auto">
      <TenantBasic :currentPage="currentPage" :pageOne="pageOne" :useTenantDetails="useTenantDetails" :loadingBtn="loadingBtn" />
    </div>
    <div class="w-75 position-relative mx-auto">
      <TenantBuildingInfo :currentPage="currentPage" :pageTwo="pageTwo" :useTenantBuildingInfo="useTenantBuildingInfo" :loadingBtn="loadingBtn" />
    </div>
    <div class="w-75 position-relative mx-auto">
      <TenantRental :currentPage="currentPage" :pageThree="pageThree" :useTenantRental="useTenantRental" :loadingBtn="loadingBtn" />
    </div>
  </div>
</template>
<script>
import TenantBasic from "@/new-components/tenant-items/TenantBasic.vue";
import TenantBuildingInfo from "@/new-components/tenant-items/TenantBuildingInfo.vue";
import TenantRental from "@/new-components/tenant-items/TenantRental.vue";
import { buildingPage1, buildingPage2, tenantPage1, tenantPage2, tenantPage3, getallbuilding, getCurrentTenant, getUnits } from "@/utils/apis.js";
import Preloader from "@/new-components/Preloader/Preloader.vue";
export default {
  data() {
    return {
      id: "",
      currentPage: 1,
      pageOne:{
        name:"",
        email:"",
        phone:"",
        address:"",
        city:"",
        state:"Andhra Pradesh",
        zip:"",
        country:"India",
      },
      pageTwo:{
        selectedBuilding:"",
        bed_id:"",
        unit_id:"",
        unit_data:[],
        buildingsData:[],
        createdAt: new Date(),
        dateOfJoining: new Date(),
      },
      pageThree:{
          is_individual_company_account:false,
          tenant_type:"Individual",
          advance_amount:"",
          due_date: new Date(),
          monthly_rental_plan:"",
          old_due:"",
          total_due:"",
          amount_collected:"",
          due_pending:"",
          account_name:"",
      },
      loadingBtn:0,
      isEdit: false,
    };
  },
  methods: {
    async setBuildingInfo(name, phone, address, country, city, zip) {
      this.name = name;
      this.phone = phone;
      this.address = address;
      this.country = country;
      this.city = city;
      this.zip = zip;

      if (
        !this.name ||
        !this.phone ||
        !this.address ||
        !this.country ||
        !this.city ||
        !this.zip
      ) {
        this.$toasted.error("Please fill all details", {
          position: "top-center",
          duration: 3000,
        });
      } else {
        const apiBody = {
          building_name: this.name,
          phone_no: this.phone,
          address: this.address,
          country: this.country,
          state: "West Bengal", //hard coded for now
          city: this.city,
          zipcode: this.zip,
        };
        if(this.id){
          apiBody.id = this.id;
        }

        const data = await buildingPage1(apiBody);
        if (data.success) {
          this.currentPage = 2;
          this.id = data.building_id;
          this.$toasted.success("Building successfully inserted!", {
            position: "top-center",
            duration: 3000,
          });
        } else {
          this.$toasted.error("Please try again later!!", {
            position: "top-center",
            duration: 3000,
          });
        }
      }
    },
    async setBuildingImages(files) {
      this.images = files;
      console.log("Images here:", this.images);
      const data = await buildingPage2(this.id, this.images);
      console.log("Data we got", data);

      if (data.success) {
        this.currentPage = 3;
        this.$toasted.success("Building successfully inserted!", {
          position: "top-center",
          duration: 3000,
        });
      } else {
        this.$toasted.error("Please try again later!!", {
          position: "top-center",
          duration: 3000,
        });
      }
    },

    async useTenantDetails(){
      let body = {
        name: this.pageOne.name,
        email: this.pageOne.email,
        phone_no: this.pageOne.phone,
        address: this.pageOne.address,
        city: this.pageOne.city,
        state: this.pageOne.state,
        zipcode: parseInt(this.pageOne.zip),
        country: this.pageOne.country,
      }
      if(this.id){
        body.tenant_id = parseInt(this.id);
      }
      this.loadingBtn = 1;
      const res = await tenantPage1(body);
      console.log("Response:", res);
      if(res.success){
          this.id = res.tenant_id;
          this.$toasted.success("Step 1 Completed", {
            position: "top-center",
            duration: 3000,
          });
          this.currentPage = 2;
      }
      else{
          this.$toasted.error("Please try again later!!", {
            position: "top-center",
            duration: 3000,
          });
      }
      this.loadingBtn = 0;
    },
    async useGetallBuilding(){
      const response = await getallbuilding();
      this.loader= false;
      this.pageTwo.buildingsData = response.buildings
        ? this.formatbuildingsdata(response.buildings)
        : [];
      console.log(response);
    },
    formatbuildingsdata(data) {
    let array = [];
    data.forEach((element) => {
      array.push({
        id: element.id,
        name:element.building_name,
        number_of_units:element.no_of_units,
        number_of_tenants:element.owner,
        // image:element.image_url,
        total_earnings:element.amount__sum,
      });
    });
    console.log(array);
    return array;
    },

    async useTenantRental(){
      const body = {
          is_individual_company_account: this.pageThree.is_individual_company_account,
          tenant_type: this.pageThree.tenant_type,
          advance_amount: parseInt(this.pageThree.advance_amount),
          due_date: this.pageThree.due_date.toISOString().split('T')[0],
          monthly_rental_plan: parseInt(this.pageThree.monthly_rental_plan),
          old_due: parseInt(this.pageThree.old_due),
          total_due: parseInt(this.pageThree.total_due),
          amount_collected: parseInt(this.pageThree.amount_collected),
          due_pending: parseInt(this.pageThree.due_pending),
          account_name: this.pageThree.account_name,
          tenant_id: parseInt(this.id ? this.id : 14),
        }
        this.loadingBtn = 2;
      const res = await tenantPage3(body);
      this.loadingBtn = 0;
      console.log("Response:", res);
      this.$toasted.success("Step 3 Completed", {
            position: "top-center",
            duration: 3000,
          });
      this.$router.push("/pages/tenants");
    },

    async useTenantBuildingInfo(){
      const body = {
        tenant_id: parseInt(this.id ? this.id : 14),
        building_id: this.pageTwo.selectedBuilding.name,
        bed_id: this.pageTwo.bed_id,
        unit_id: this.pageTwo.unit_id ? this.pageTwo.unit_id.id : 1,
        created_at: this.pageTwo.createdAt.toISOString().split('T')[0],
        date_of_joining: this.pageTwo.dateOfJoining.toISOString().split('T')[0],
      }
      this.loadingBtn = 3;
      const res = await tenantPage2(body);
      if(res.success){
          this.$toasted.success("Step 2 Completed", {
            position: "top-center",
            duration: 3000,
          });
          this.currentPage = 3;
      }
      else{
          this.$toasted.error("Please try again later!!", {
            position: "top-center",
            duration: 3000,
          });
      }
      this.loadingBtn = 0;
    },

    async useGetCurrentTenant(){
      if(this.$router.currentRoute.path.includes('edit-tenant')){
        const response = await getCurrentTenant(this.$router.currentRoute.params.id);
        this.id = this.$router.currentRoute.params.id;
        console.log("(*&**",response);
        this.pageOne.name = response.data.tenant.name;
        this.pageOne.email = response.data.tenant.email;
        this.pageOne.phone = response.data.tenant.phone_no;
        this.pageOne.address = response.data.tenant.address;
        this.pageOne.city = response.data.tenant.city;
        this.pageOne.state = response.data.tenant.state;
        this.pageOne.zip = response.data.tenant.zipcode;
        this.pageOne.country = response.data.tenant.country;

        this.pageTwo.createdAt = new Date(response.data.tenant.created_at);
        this.pageTwo.dateOfJoining = new Date(response.data.tenant.date_of_joining);
        this.pageTwo.selectedBuilding = response.data.tenant.building;
        this.pageTwo.bed_id = response.data.tenant.bed_id;
        this.pageTwo.unit_id = { name:response.data.tenant.unit , id : response.data.tenant.unit};

        // this.pageThree.is_individual_company_account = response.data.tenant.is_individual_company_account;
        this.pageThree.tenant_type = response.data.tenant.tenant_type;
        this.pageThree.account_name = response.data.tenant.account_name;
        this.pageThree.advance_amount = response.data.tenant.advance_amount;
        this.pageThree.due_date = new Date(response.data.tenant.due_date);
        this.pageThree.monthly_rental_plan = response.data.tenant.monthly_rental_plan;
        this.pageThree.old_due = response.data.tenant.old_due;
        this.pageThree.total_due = response.data.tenant.total_due;
        this.pageThree.amount_collected = response.data.tenant.amount_collected;
        this.pageThree.due_pending = response.data.tenant.due_pending;
      }
    },

    async useUnitstable(id) {
      const result = await getUnits(id ? id : "");
      this.rows = result.count; 
      this.loader = false;
      if(result.results){
        let array = []
        result.results.forEach((element) => {
          array.push({
            id: element.id,
            name: element.id ? element.id : element.id,
          })
        });
        this.pageTwo.unit_data = [...array];
      }
      // this.pageTwo.unit_data = [...result.results]
    },
    
    home() {
      this.$router.push("/pages/listings");
    },
    back() {
      this.$router.push("/pages/tenants");
    },
  },
  mounted() {
    this.useGetallBuilding();
    this.useGetCurrentTenant();
    if(this.$router.currentRoute.path.includes('edit')){
      this.isEdit = true;
      this.currentPage = 3;
    }
    window.onbeforeunload = function() {
      return "Data will be lost if you leave the page, are you sure?";
    };
  },
  components: {
    TenantBasic,
    TenantBuildingInfo,
    TenantRental,
    Preloader,
  },
  watch: {
    'pageTwo.selectedBuilding': function (newVal) {
        if(newVal.id === ""){
        this.useUnitstable();
        console.log("All Buildings");
        }else{
          console.log(newVal.id);
          this.useUnitstable(newVal.id);
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.custom-blank-navbar {
  margin-bottom: 3rem;
}
.btn-close{
  width: 73px;
  height: 26px;

  background: #FFFFFF;
  border: 0.5px solid #F72585;
  color:#f72585;
  border-radius: 4px;
}
</style>