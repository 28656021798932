import { render, staticRenderFns } from "./TenantBasic.vue?vue&type=template&id=6569067c&scoped=true&"
import script from "./TenantBasic.vue?vue&type=script&lang=js&"
export * from "./TenantBasic.vue?vue&type=script&lang=js&"
import style0 from "./TenantBasic.vue?vue&type=style&index=0&id=6569067c&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6569067c",
  null
  
)

export default component.exports